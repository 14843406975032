import { Instagram } from "lucide-react";

import BG from "../assets/bg.png";
import Passar from "../assets/passar.png";
import Facebook from "../assets/svg/facebook.svg";
import Linkedin from "../assets/svg/linkedIn.svg";

export const ComingSoon = () => {
  return (
    <div
      className="bg-cover bg-center bg-no-repeat h-screen w-full min-h-screen max-h-screen px-6"
      style={{ backgroundImage: `url(${BG})` }}
    >
      <div className="flex flex-col items-center w-full justify-evenly h-screen">
        <img src={Passar} />
        <h1 className="text-lg md:text-2xl lg:text-4xl text-[#32373E]  lg:w-[80%] font-bold">
          Spearheading a revolutionary era of marketing in the cradle of
          civilization.
        </h1>
        <div className="flex flex-col items-center ">
          <p className="text-[#F0F1F3] text-md lg:text-2xl font-sans font-bold">
            CONTACT US
          </p>
          <div className="flex flex-col mt-6 gap-y-2">
            <p className="text-[#F0F1F3] text-md lg:text-xl font-sans ">
              connect@passaragency.com
            </p>
            <p className="text-[#F0F1F3] text-md lg:text-xl font-sans ">
              +964 (0) 785 112 1212
            </p>
          </div>

          <div className="flex flex-row justify-between items-center gap-10 mt-6">
            <a
              className="no-underline cursor-pointer"
              href="https://instagram.com/passaragency?igshid=OGQ5ZDc2ODk2ZA=="
              target="_blank"
            >
              <Instagram color="#F0F1F3" width={24} height={24} />
            </a>

            <a
              className="no-underline cursor-pointer"
              href="https://www.facebook.com/profile.php?id=61551260188805&mibextid=ZbWKwL"
              target="_blank"
            >
              <img src={Facebook} width={24} height={24} />
            </a>
            <a
              className="no-underline cursor-pointer"
              href="https://www.linkedin.com/company/passaragency/"
              target="_blank"
            >
              <img src={Linkedin} width={24} height={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
