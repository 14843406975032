import "./App.css";
import { ComingSoon } from "./pages/coming-soon";

function App() {
  return (
    <div className="App" >
      <ComingSoon />
    </div>
  );
}

export default App;
